<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <h3><strong>{{ title }}</strong></h3>
          <h1 class="text-danger">
            <strong>{{ counter }} {{ description }}</strong>
          </h1>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BaseTask',
  props: {
    counter: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
  watch: {
    counter(val) {
      this.counter = val
    },
    title(val) {
      this.title = val
    },
    description(val) {
      this.description = val
    },
  },
}
</script>
