<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <h3><strong>{{ title }}</strong></h3>
            </b-col>
            <b-col
              cols="12"
              lg="2"
              class="mx-auto"
            >
              <b-form-rating
                id="rating-lg-no-border"
                v-model="value"
                variant="danger"
                no-border
                size="lg"
                style="pointer-events: none"
              />
            </b-col>
            <b-col
              cols="12"
              class="text-center"
            >
              <h3>{{ message }}</h3>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BasePerformance',
  props: {
    title: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: Number,
    },
    message: {
      required: true,
      type: String,
    },
  },
  watch: {
    value(val) {
      this.value = val
    },
    title(val) {
      this.title = val
    },
    message(val) {
      this.message = val
    },
  },
}
</script>
