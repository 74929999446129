<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <h3><strong>{{ title }}</strong></h3>
            </b-col>
            <b-col cols="12">
              <div class="pre-scrollable">
                <b-list-group>
                  <b-list-group-item
                    v-for="milestone in milestones"
                    :key="milestone.order_date"
                  >
                    <b-badge
                      variant="danger"
                      class="mb-1"
                    >
                      {{ milestone.order_date }}
                    </b-badge>
                    <h3 class="text-primary">
                      {{ description }} {{ milestone.orders }}
                    </h3>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BaseMilestone',
  props: {
    title: {
      required: true,
      type: String,
    },
    milestones: {
      required: true,
      type: Array,
    },
    description: {
      required: true,
      type: String,
    },
  },
  watch: {
    milestones(val) {
      this.milestones = val
    },
    title(val) {
      this.title = val
    },
    description(val) {
      this.description = val
    },
  },
}
</script>

<style scoped>
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
</style>
