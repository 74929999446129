<template>
  <b-row class="mb-2">
    <b-col cols="12">
      <h1>{{ greetingMessage }}, <strong>{{ name }}</strong></h1>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BaseWelcome',
  props: {
    name: {
      required: true,
      type: String,
    },
    greetingMessage: {
      required: true,
      type: String,
    },
  },
  watch: {
    name(val) {
      this.name = val
    },
    greetingMessage(val) {
      this.greetingMessage = val
    },
  },
}
</script>
