<template>
  <b-overlay :show="show">
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-text>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="text-uppercase">
                  <strong>{{ title }}</strong>
                </h3>
              </b-col>
              <b-col cols="12">
                <h5>{{ message }}
                  <b-link
                    target="blank"
                    class="text-danger"
                    @click="handleDownloadTutorial"
                  ><feather-icon
                    width="18"
                    height="18"
                    :icon="icon"
                  /> {{ $t('guideline_button') }}</b-link>
                </h5>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
export default {
  name: 'BaseGuideline',
  props: {
    title: {
      required: true,
      type: String,
    },
    message: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      type: 2, // 1 - b2bpro, 2 - smarttruck
      show: false,
    }
  },
  watch: {
    title(val) {
      this.title = val
    },
    message(val) {
      this.message = val
    },
  },
  methods: {
    async handleDownloadTutorial() {
      this.show = true
      const response = await this.$http.get(`/download-tutorial/${this.type}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'SMARTTRUCK 3.1 User Tutorial (English).pdf')
      document.body.appendChild(fileLink)
      fileLink.click()
      this.show = false
    },
  },
}
</script>
